//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mixin as clickaway } from 'vue-clickaway';

export default {
    name: 'SocialSharer',
    mixins: [clickaway],
    props: {
        networklist: {
            type: Array,
            default: [],
        },
        title: {
            type: String,
            default: '',
        },
        quote: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        hashtags: {
            type: String,
            default: '',
        },
        twitterUser: {
            type: String,
            default: '',
        },
        media: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            imagePath: this.$config.pimcoreUrl,
            showOptions: false,
            transEnd: true,
            shareUrl: '',
            positionLeft: false,
        };
    },
    watch: {
        showOptions() {
            this.transEnd = false;
        },
    },
    mounted() {
        this.shareUrl = window.location.href;
        this.$refs.socialSharing.addEventListener('transitionend', () => {
            this.transEnd = true;
        });
    },
    methods: {
        openShare() {
            if (navigator.share) {
                this.openMobileShare();
            } else {
                this.checkPosition();
                this.showOptions = true;
            }
        },
        away() {
            this.showOptions = false;
        },
        openMobileShare() {
            navigator
                .share({
                    title: this.title,
                    text: this.description,
                    url: this.shareUrl,
                })
                .then(() => {
                    // thanks for sharing action
                })
                .catch((e) => {
                    console.error(e);
                });
        },
        checkPosition() {
            const elmWidth = this.$refs.socialSharingOptions.getBoundingClientRect().width;
            const positionRight = this.$refs.socialSharingTrigger.getBoundingClientRect().right;
            const windowWidth = window.innerWidth;
            const spaceRight = windowWidth - positionRight;

            if (spaceRight < elmWidth) {
                this.positionLeft = true;
            } else {
                this.positionLeft = false;
            }
        },
    },
};
