//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'ContactBox',
    props: ['data'],
};
