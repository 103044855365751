//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'TextBlock',
    props: ['data', 'text-element', 'size', 'animation'],
    computed: {
        textBlockLink() {
            return this.data?.isDownloadLink ? `${this.$config.pimcoreUrl}${this.data?.href?.path}` : this.data?.href?.path;
        },
    },
};
