//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'vue-tabs-item',
    props: {
        title: {
            type: String,
            required: true,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        show: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            active: false
        };
    },
    watch: {
        show() {
            this.active = this.show;
            if (this.active) {
                this.$emit('show', this.active);
            } else {
                this.$emit('hide', this.active);
            }
        }
    },
    mounted() {
        this.active = this.show;
    }
};
