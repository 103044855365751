import { render, staticRenderFns } from "./Merchant.vue?vue&type=template&id=3bf40ab2&"
import script from "./Merchant.vue?vue&type=script&lang=js&"
export * from "./Merchant.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MerchantStage: require('/usr/src/app/components/Merchant/MerchantStage.vue').default,MerchantProducts: require('/usr/src/app/components/Merchant/MerchantProducts.vue').default})
