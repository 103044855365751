var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"colorpalette-item",class:{
            'colorpalette-item--bicolor': _vm.bicolor,
            'colorpalette-item--invalid': _vm.invalid,
            'colorpalette-item--selected': _vm.isSelected,
            'colorpalette-item--disabled': _vm.isDisabled,
            'colorpalette-item--loading': _vm.isLoading,
            'colorpalette-item--animated': _vm.hasDelayAnimations,
            'colorpalette-item--huge': _vm.isHuge,
        },attrs:{"title":_vm.showTooltip ? null :_vm.colorName},on:{"click":_vm.showTooltipMobile}},[_c(_vm.isElement,{tag:"component",staticClass:"colorpalette-item__wrapper",attrs:{"href":_vm.href,"disabled":_vm.isDisabled},on:{"click":_vm.handleClick}},[_c('span',{staticClass:"colorpalette-item-element",style:(_vm.style)}),_vm._v(" "),_c('div',{staticClass:"colorpalette-item-element__image-wrapper"},[(_vm.image)?_c('img',{staticClass:"colorpalette-item-element__image",attrs:{"src":_vm.image}}):_vm._e()]),_vm._v(" "),_c('svg',{directives:[{name:"show",rawName:"v-show",value:(_vm.glossy),expression:"glossy"}],staticClass:"glossy",staticStyle:{"enable-background":"new 0 0 35 24"},attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","viewBox":"0 0 35 24","xml:space":"preserve"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","fill":_vm.glossyFill,"d":"M0.9,23c14.9,0,27.8-8.5,34-20.7l-2.7-1.4C26.5,12.2,14.6,20,0.9,20V23z"}})])]),_vm._v(" "),(_vm.tooltip)?_c('div',{staticClass:"colorpalette-item__tool-tip"},[_vm._v("\n            "+_vm._s(_vm.colorName)+"\n        ")]):_vm._e()],1),_vm._v(" "),(_vm.showLabel)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.data.name)}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }