//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import {PRODUCT_CATEGORY_TYPE_SHOWERTILE} from '../../constants';

export default {
    name: 'ProductDefinitionList',
    props: ['keys'],
    data() {
        return {
            // keys defined by props or fallback to this list
            listKeys: [],
            keyValues: []
        }
    },
    computed: {
        ...mapGetters([
            'getStoredProduct',
            'getStoredProductVariant',
        ]),
        variant() {
            return this.getStoredProductVariant;
        },
        product() {
            return this.getStoredProduct;
        },
    },
    watch: {
        getStoredProductVariant() {
            this.updateData();
        }
    },
    methods: {
        getKeyTranslation(key) {
            return this.$t(`product.tecdetail.definitionlist.${key}`) + ':';
        },
        getValue(key) {
            let suffix = '';
            if (key === 'dimensionsString' && !this.variant?.[key]?.includes(this.$t(`dimensions.units`))) {
                suffix = ' ' + this.$t(`dimensions.units`);
            }

            return this.variant[key] ? this.variant[key] + suffix : (this.product[key] ? this.product[key] : null);
        },
        updateData() {
            this.listKeys = [];
            this.keyValues = [];
            this.listKeys = [...this.keys] || [
                'dimensionsString', 'weight', 'capacity', 'edgeHeight', 'cornerRadius', 'drainDiameter', 'lyingDimension', 'constructionHeight', 'sealingWaterHeight', 'bowdenCableLength', 'drainCapacity','inletCapacity', 'scopeOfDelivery','technicalHints',
            ];

            this.listKeys.forEach((element)=>{
                let value = this.getValue(element)
                let obj = {
                    key: element,
                    val: value,
                }
                if (obj.val && obj.val !== '0 x 0 x 0 mm'){
                    this.keyValues.push(obj)
                }
            })

            // Filter for special types
            if (this.getStoredProduct.category.typeSegment === PRODUCT_CATEGORY_TYPE_SHOWERTILE) {
                this.listKeys = this.listKeys.filter(i => i !== 'capacity')
            }
        }
    },
    mounted() {
        this.updateData();
    }
};
