//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
    name: 'NewsletterSubscription',
    props: {},
    data() {
        return {
            email: null,
        };
    },
    computed: {
        ...mapGetters(['getStoredSiteProperties']),
    },
    methods: {
        submit() {
            let link = this.$getLocalizedSlug(this.getStoredSiteProperties.newsletterform_page?.id);

            if (this.email) {
                link = `${link}?email=${encodeURIComponent(this.email)}`;
            }

            window.location.href = link;
        },
    },
};
