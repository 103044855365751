//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProductTeaser from '../Partials/ProductTeaser/ProductTeaser.vue';

export default {
    components: { ProductTeaser },
    name: 'Products',
    props: ['data'],
};
