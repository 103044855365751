//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
    name: 'Breadcrumb',
    computed: {
        ...mapGetters(['getStoredRootline']),
        pages() {
            return this.getStoredRootline?.map((page, i) => {
                return {
                    id: page?.id,
                    href: page?.id ? this.$getLocalizedSlug(page.id) : null,
                    name: page.navtitle || page.title || page.name || this.getNames(page),
                    tabindex: i === this.getStoredRootline.length - 1 ? -1 : null,
                    ariaCurrent: i === this.getStoredRootline.length - 1 ? 'page' : null,
                };
            });
        },
    },
    methods: {
        // Scroll focused breadcrumb into view
        focus() {
            if (window.scrollY) {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            }
        },
        getNames(page) {
            return (page.name2 !== null) ? `${page.name1} ${page.name2}` : `${page.name1}`;
        }
    },
};
