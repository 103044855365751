export default {
    methods: {
        getVariantPrice(variant, colorClass = null, range = null, getLowest = true) {
            if (!variant) return null;
            const productRange = range?.toUpperCase() ?? this.getSelectedProductRange();
            if (productRange === 'PT' || productRange === 'RU') {
                return null;
            }
            if (colorClass) {
                let priceObj = variant?.prices?.[`A_PIM_Basispreis_${colorClass}`]?.[productRange] ?? null;
                if (priceObj) {
                    return this.getValidPrice(priceObj);
                }
                return null;
            } else {
                if (!getLowest) return null;
                return this.getVariantDetailPrice(variant, productRange);
            }
        },
        getBestPrice(l) {
            if (!this.product?.variants) {
                return null;
            }

            const productRange = this.getSelectedProductRange();

            if (productRange === 'PT' || productRange === 'RU') {
                return null;
            }

            const productPriceKeys = ['A_PIM_Basispreis_W', 'A_PIM_Basispreis_H', 'A_PIM_Aufpreis', 'A_PIM_Basispreis_P', 'A_PIM_Endpreis_BRUTTO'];
            let bestPrice = null;

            for (const variant of this.product.variants) {
                if (variant.prices) {
                    for (const [key, productRangePrice] of Object.entries(variant.prices)) {
                        if (productPriceKeys.includes(key) && Array.isArray(productRangePrice[productRange])) {
                            const price = this.getValidPrice(productRangePrice[productRange]);
                            if (bestPrice === null || (price && bestPrice.price > price.price)) {
                                bestPrice = price;
                            }
                        }
                    }
                }
            }
            return bestPrice;
        },
        getVariantDetailPrice(variant, range = null) {
            const productRange = range ?? this.getSelectedProductRange();
            if (productRange === 'PT' || productRange === 'RU') {
                return null;
            }
            const productPriceKeys = ['A_PIM_Basispreis_W', 'A_PIM_Basispreis_H', 'A_PIM_Aufpreis', 'A_PIM_Basispreis_P', 'A_PIM_Endpreis_BRUTTO'];
            if (variant.prices) {
                for (const [key, productRangePrice] of Object.entries(variant.prices)) {
                    if (productPriceKeys.includes(key) && productRangePrice[productRange]) {
                        return this.getValidPrice(productRangePrice[productRange]);
                    }
                }
            }
            return null;
        },
        getValidPrice(productRangePrices) {
            const date = new Date();
            for (const price of productRangePrices) {
                if (new Date(price.validFrom) <= date && new Date(price.validUntil) >= date) {
                    return price;
                }
            }
            return null;
        },
    },
};
