//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ImageCopyright from '../Partials/ImageCopyright.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'StageContent',
    components: { ImageCopyright },
    props: ['data', 'pressnews'],
    computed: {
        ...mapGetters(['getStoredPage']),
        safeTitle() {
            return this.headline || this.getStoredPage?.title;
        },
        image() {
            return this.$mapImageData(this.data?.image?.image);
        },
        imageMobile() {
            return this.$mapImageData(this.data?.imageMobile?.image);
        },
        text() {
            return this.data?.text;
        },
        headline() {
            if (this.data?.style !== 'inspiration') {
                return this.data?.headline || this.getStoredPage.title;
            }

            return this.data?.headline;
        },
        roofline() {
            if (this.data?.style !== 'inspiration') {
                return null;
            }

            return this.data?.roofline || this.getStoredPage.title;
        },
    },
};
