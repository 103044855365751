//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Mediaplayer from '../Partials/Mediaplayer/Mediaplayer.vue';
import TextBlock from '../Partials/TextBlock.vue';

export default {
    name: 'TextVideo',
    components: {TextBlock, Mediaplayer},
    props: ['data'],
    data() {
        return {
            mediaPath: this.$config.pimcoreUrl,
            isMobile: !!this.$mobileDetect.mobile(),
            playAudio: true,
        };
    },
    computed: {
        caption() {
            if (this.data?.video?.description) {
                return this.data?.video?.description;
            }

            return this.data?.caption;
        },
    },
    mounted() {
        this.playAudio = !this.playAudio;
    },
    methods: {
        toggleAudio() {
            if (this.isMobile && this.data.animatedVideoMobile) {
                this.$refs.mobileplayer.muted = !this.$refs.mobileplayer.muted;
            } else {
                this.$refs.videoplayer.muted = !this.$refs.videoplayer.muted;
            }
            this.playAudio = !this.playAudio;
        },
    },
};
