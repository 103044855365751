//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ColorpaletteItem from './ColorpaletteItem.vue';
export default {
    name: 'ColorpaletteProduct',
    components: { ColorpaletteItem },
    props: {
        colors: {
            type: Array,
            required: true,
        },
        limit: {
            type: Number,
            default: null,
        },
        href: {
            type: String,
            default: null,
        },
        interactive: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Object,
            default: null,
        },
        showLabel: {
            type: Boolean,
            default: false,
        },
        showTooltip: {
            type: Boolean,
            default: false,
        },
        pendingColorKey: {
            type: String,
            default: null,
        },
        hasDelayAnimations: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            limitData: this.limit,
        };
    },
    computed: {
        isElement() {
            return this.href ? 'a' : 'span';
        },
        limitedColors() {
            if (!this.limitData) return this.colors;

            return this.colors?.slice(0, this.limitData);
        },
    },
    methods: {
        loadMore() {
            this.limitData = null;
        },
        selectColor(color) {
            this.$emit('input', color);
        },
        isSelected(color) {
            return this.value?.extKey === color.extKey;
        },
    },
};
