//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
    name: 'FooterLegal',
    props: {},
    computed: {
        ...mapGetters([
            'getStoredSiteProperties',
        ]),
    },
    data() {
        return {
            legal: null,
            copyright: ''
        };
    },
    created() {
        try {
            this.legal = this.getStoredSiteProperties?.footer?.legal;
            this.copyright = this.getStoredSiteProperties?.copyright;
        } catch(e) {
            console.error(e);
        }
    },
    methods: {},
};
