//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
    VsaIcon
} from 'vue-simple-accordion';
import { mapGetters } from 'vuex';

export default {
    name: 'FooterNavigationMain',
    components: {
        VsaList,
        VsaItem,
        VsaHeading,
        VsaContent,
        VsaIcon
    },
    computed: {
        ...mapGetters([
            'getStoredSiteProperties'
        ]),
    },
    data() {
        return {
            lists: null,
            links: null,
        };
    },
    created() {
        try {
            this.lists = [
                {
                    title: this.$t('footer.accordeon.title.inspiration'),
                    links: this.getStoredSiteProperties?.footer?.inspiration
                },
                {
                    title: this.$t('footer.accordeon.title.products'),
                    links: this.getStoredSiteProperties?.footer?.products
                }
            ];
            this.links = this.getStoredSiteProperties?.footer?.links;
        } catch (e) {
            console.error(e);
        }
    },
    methods: {},
};
