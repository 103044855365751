//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const MODAL_SIZE_SMALL = 'small';
const MODAL_SIZE_MEDIUM = 'medium';

export default {
    name: 'StageModal',
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        headline: {
            type: String,
            default: null,
        },
        size: {
            type: String,
            default: MODAL_SIZE_MEDIUM,
            validator: v => [MODAL_SIZE_SMALL, MODAL_SIZE_MEDIUM].includes(v),
        },
    },
    data() {
        return {
            headlineId: `modal-headline-${this._uid}`,
            MODAL_SIZE_SMALL,
            MODAL_SIZE_MEDIUM,
            lastActiveElement: null,
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        handleEscKey(e) {
            if (e.key === 'Escape' && this.active) {
                this.close();
            }
        },
        trapFocus(e) {
            if (!this.$refs.modalContent.contains(e.target)) {
                e.preventDefault();
                this.$refs.modalContent.focus();
            }
        },
        setupFocusTrap() {
            this.lastActiveElement = document.activeElement;
            this.$nextTick(() => {
                const focusableElements = this.$refs.modalContent.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
                if (focusableElements.length) {
                    focusableElements[0].focus();
                } else {
                    this.$refs.modalContent.focus();
                }
            });
            document.addEventListener('focus', this.trapFocus, true);
        },
        removeFocusTrap() {
            document.removeEventListener('focus', this.trapFocus, true);
            if (this.lastActiveElement) {
                this.lastActiveElement.focus();
            }
        },
    },
    watch: {
        active(newVal) {
            if (newVal) {
                this.setupFocusTrap();
            } else {
                this.removeFocusTrap();
            }
        }
    },
    mounted() {
        document.addEventListener('keydown', this.handleEscKey);
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.handleEscKey);
        this.removeFocusTrap();
    }
};
