//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Tab from '~/components/Tab/Tab.vue';
import Tabs from '~/components/Tab/Tabs.vue';
import ProductDescription from './ProductDescription.vue';
import ProductTecDetails from './ProductTecDetails.vue';
import Productdownloads from './ProductDownloads.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'ProductTabs',
    components: {
        Productdownloads,
        ProductTecDetails,
        ProductDescription,
        Tab,
        Tabs,
    },
    computed: {
        ...mapGetters(['getStoredProductVariant', 'getStoredProduct']),
        variant() {
            return this.getStoredProductVariant || {};
        },
        product() {
            return this.getStoredProduct || {};
        },
    },
    data() {
        return {
            openTabIndex: null,
            imagePath: this.$config.pimcoreUrl,
            descriptionKeys: ['shape', 'functions', 'installationType', 'design', 'suitableFor', 'accessoryCategory', 'imageText', 'usageNote'],
            techDetailKeys: [
                'technicalHintTextFeature',
                'certificationTextFeature',
                'certificationText',
                'dimensionsString',
                'weight',
                'inletCapacity',
                'capacity',
                'edgeHeight',
                'cornerRadius',
                'drainDiameter',
                'lyingDimension',
                'constructionHeight',
                'minimumShoringHeight',
                'sealingWaterHeight',
                'bowdenCableLength',
                'drainCapacity',
                'scopeOfDelivery',
                'technicalHints',
            ],
        };
    },
    beforeMount() {
        if (this.product.category?.typeSegment == 'Zubehör') {
            const index = this.techDetailKeys.indexOf('weight');
            this.techDetailKeys.splice(index + 1, 0, 'material');
        }
    },
    mounted() {
        if (window.location.hash) {
            this.openTabIndex = parseInt(window.location.hash.substr(1));
        } else {
            this.openTabIndex = 0;
        }
        this.$nextTick(() => {
            if (window.location.hash) {
                window.scrollTo({
                    top: this.getOffsetTop(this.$refs.tabs.$el) - 130,
                    left: 0,
                    behavior: 'smooth',
                });
            }
        });
    },
    methods: {
        getOffsetTop(elem) {
            let offsetLeft = 0;
            do {
                if (!isNaN(elem.offsetTop)) {
                    offsetLeft += elem.offsetTop;
                }
            } while ((elem = elem.offsetParent));
            return offsetLeft;
        },
        changeTab(index) {
            if (typeof index !== 'number') return;
            if (!index && history.pushState) return history.pushState({}, null, window.location.href.split('#')[0]);
            window.location.hash = index;
        },
        objectHas(object, keys) {
            let bool = false;
            keys.forEach((arg) => {
                if (object[arg] && object[arg] !== null) {
                    bool = true;
                }
            });
            return bool;
        },
    },
};
