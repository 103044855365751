//
//
//
//
//
//
//
//
//
//
//

import DownloadBox from '../DownloadBox.vue';
import ContactBox from '../Contact/ContactBox.vue';
export default {
    name: 'PressReleaseFooter',
    components: { ContactBox, DownloadBox },
    props: ['data'],
    computed: {
        contacts() {
            const contacts = this.data?.contact;
            if (!contacts || !contacts.length) return null;
            return contacts;
        },
        downloads() {
            const downloads = this.data?.downloads;
            if (!downloads || !downloads.length) return null;
            return {
                key: 'pressmaterial',
                items: downloads,
            };
        },
    },
};
