//
//
//
//

import MarkerClusterer from '@googlemaps/markerclustererplus';

export default {
    name: 'GoogleMap',
    props: ['markers', 'cluster', 'country'],
    data() {
        return {
            markerClusterer: null,
        };
    },
    watch: {
        markers(markers, oldMarkers) {
            if (Array.isArray(markers)) {
                if (Array.isArray(oldMarkers)) {
                    oldMarkers.forEach((marker) => {
                        marker.setMap(null);
                    });
                }
                if (this.markerClusterer) {
                    this.markerClusterer.clearMarkers();
                }
                this.loadMarkers();
            }
        },
        country(val) {
            if (val) {
                this.centerCountry(val)
            }
        },
    },
    methods: {
        centerCountry(country) {
            if (country && window.google) {
                const regionNames = new Intl.DisplayNames([this.$i18n.locale], { type: 'region' });
                const geocoder = new google.maps.Geocoder();
                geocoder.geocode({
                    address: regionNames.of(country),
                    componentRestrictions: {
                        country: country
                    }
                }, (results, status) => {
                    if (status === google.maps.GeocoderStatus.OK) {
                        this.googleMap.setCenter(results[0].geometry.location);
                        this.googleMap.fitBounds(results[0].geometry.viewport);
                    }
                });
            }
        },
        loadMarkers() {
            if (this.cluster === true) {
                // Add a marker clusterer to manage the markers.
                // @ts-ignore MarkerClusterer defined via script
                this.markerClusterer = new MarkerClusterer(this.googleMap, this.markers, {
                    clusterClass: 'custom-clustericon',
                    styles: [
                        {
                            width: 30,
                            height: 30,
                            className: 'custom-clustericon-1',
                        },
                    ],
                });
            } else {
                this.markers.forEach((marker) => {
                    marker.setMap(this.googleMap);
                });
            }
        },
        checkMarkersInView() {
            const markersInView = [];
            this.markers.forEach((marker) => {
                if (this.googleMap.getBounds().contains(marker.getPosition())) {
                    markersInView.push(marker);
                }
            });
            this.$emit('markersInView', markersInView);
        },
        loadGoogleMap() {
            this.googleMap = new google.maps.Map(this.$refs.map, {
                mapId: '2d478ae0bdbc3d19', // created in google cloud console
                center: { lat: 52.0849347, lng: 9.6404649 },
                zoom: 5,
                mapTypeControlOptions: {
                    mapTypeIds: [],
                },
            });

            if (this.markers) {
                this.loadMarkers();
                google.maps.event.addListener(this.googleMap, 'bounds_changed', this.checkMarkersInView);
            }

            this.$emit('loaded', this.googleMap);
        },
    },
    mounted() {
        this.isLoadingMaps = true;
        if (process.client) {
            if (process.env.NUXT_ENV_GOOGLE_MAPS_KEY !== '') {
                this.$initGoogleMaps(process.env.NUXT_ENV_GOOGLE_MAPS_KEY).then(() => {
                    this.loadGoogleMap();
                });
            }
        }
    },
};
