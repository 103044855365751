//
//
//
//
//
//
//
//
//
//
//
//

import ImageCopyright from '../ImageCopyright.vue';

export default {
    name: 'Posterimage',
    components: { ImageCopyright },
    props: ['data', 'videoId'],
    computed: {
        image() {
            return this.$mapImageData(this.data?.image);
        },
    }
};
