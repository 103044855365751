//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'ColorpaletteItem',
    props: {
        data: {
            type: Object,
            required: true,
        },
        showLabel: {
            type: Boolean,
            default: false,
        },
        href: {
            type: String,
            default: null,
        },
        interactive: {
            type: Boolean,
            default: false,
        },
        isSelected: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        showTooltip: {
            type: Boolean,
            default: false,
        },
        hasDelayAnimations: {
            type: Boolean,
            default: false,
        },
        isHuge: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            tooltip: false
        };
    },
    computed: {
        bicolor() {
            return this.data?.parent?.key === 'Bicolour';
        },
        invalid() {
            return !this.data?.rgb && !this.data?.colorImage;
        },
        colorName() {
            let name
            if (this.$i18n.locale === 'de') {
                name = this.data?.['de'] || this.data?.name
            } else {
                name = this.data?.['en'] || this.data?.name
            }
            return name + ', ' + this.$t('surface.' + this.data.surface?.trim()) + (this.data.extKey ? ', ' + this.data.extKey : '');
        },
        image() {
            if (!this.data?.colorImage?.fullpath) return null;

            return `${this.$config.pimcoreUrl}/${this.data.colorImage.fullpath}`.replace(/([^:]\/)\/+/g, '$1');
        },
        isElement() {
            if (this.interactive) {
                return 'button';
            }
            return this.href ? 'a' : 'div';
        },
        glossy() {
            return this.data?.surface !== 'matt';
        },
        glossyFill() {
            return this.isWhite ? '#E5E5E5' : '#FFF';
        },
        isWhite() {
            const {rgb} = this.data;

            if (!rgb) {
                return false;
            } else if (rgb.replace(/\s+/g, '') === 'rgb(255,255,255)') {
                return true;
            } else if (rgb === '#fff' || rgb === '#ffffff') {
                return true;
            }
            return false;
        },
        style() {
            return {
                backgroundColor: this.data?.rgb,
            };
        },
    },
    methods: {
        handleClick() {
            if (!this.interactive || this.isDisabled) return;

            this.$emit('input', this.data);
        },
        showTooltipMobile() {
            if (!this.showTooltip) {
                this.tooltip = true;
                setTimeout(() => {
                    this.tooltip = false;
                }, 3000);
            }
        }
    },
    mounted() {
        this.tooltip = this.showTooltip;
    }
};
