//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import ImageCopyright from '../Partials/ImageCopyright.vue';

export default {
    name: 'ProductDescription',
    components: { ImageCopyright },
    props: ['keys'],
    data() {
        let listKeys = [...this.keys] || ['shape', 'functions', 'installationType', 'design'];
        // exclude imageText from listKeys to avoid listing this text
        listKeys.splice(listKeys.indexOf('imageText'), 1);
        return {
            imagePath: this.$config.pimcoreUrl,
            // keys defined by props or fallback to this list
            listKeys,
        };
    },
    computed: {
        ...mapGetters(['getStoredProduct', 'getStoredProductVariant']),
        product() {
            return this.getStoredProduct;
        },
        images() {
            return this.product?.localeImages
                ?.filter((i) => ['A_PIM_Zeichnung_Zubehör','A_PIM_Zeichnungen_Modell_Draufsicht', 'A_PIM_Zeichnungen_Modell_Perspektivenansicht', 'A_PIM_Zeichnungen_Modell_Seitenansicht', 'A_PIM_Zeichnungen_Modell_Sonstige', 'A_PIM_Zeichnungen_Modell_Vorderansicht'].includes(i.localeImage.localeAssetKey) === false)
                .map((image) => {
                    return this.$mapImageData(image.localeImage);
                });
        },
    },
    methods: {
        getValue(key) {
            if (this.getStoredProductVariant) {
                for (let i = 0; i < this.product.variants.length; i++) {
                    if (this.getStoredProductVariant === this.product.variants[i] && this.product.variants[i][key]) {
                        return this.product.variants[i][key].split(',').join(', ');
                    }
                }
            }
            return this.product[key].split(',').join(', ');
        },
        getKeyTranslation(key) {
            return this.$t(`product.description.definitionlist.${key}`) + `:`;
        },
    },
};
