//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    name: "HeaderTouchComponent",
    computed: {
        backlink(){
            if (this.isTouchScreen) {
                const pageId = this.$store.getters.getStoredPage.properties.find(p => p.name === 'header_backlink')?.object.id;
                return pageId ? this.$localeSlug(this.$findMenuItemById(pageId, this.$store.state.menu[this.$i18n.locale])) : 'javascript:history.back();';
            }
            return null;
        },
        isTouchScreen() {
            return this.$store.getters.getStoredPage && this.$store.getters.getStoredPage.properties.find(p => p.name === 'touch_screen')?.checked === true;
        },
    }
}
