import { render, staticRenderFns } from "./ProductTabs.vue?vue&type=template&id=7d78a8cd&"
import script from "./ProductTabs.vue?vue&type=script&lang=js&"
export * from "./ProductTabs.vue?vue&type=script&lang=js&"
import style0 from "./ProductTabs.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductDescription: require('/usr/src/app/components/Product/ProductDescription.vue').default,Tab: require('/usr/src/app/components/Content/Tab.vue').default,ProductTecDetails: require('/usr/src/app/components/Product/ProductTecDetails.vue').default,Tabs: require('/usr/src/app/components/Tab/Tabs.vue').default})
