//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ImageCopyright from '../Partials/ImageCopyright.vue';
import TextBlock from '../Partials/TextBlock.vue';

export default {
    name: 'TextImage',
    components: {TextBlock, ImageCopyright},
    props: ['data'],
    computed: {
        image() {
            return this.$mapImageData(this.data?.image?.image);
        },
    },
};
