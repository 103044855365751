var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"texticons grid md:grid-cols-12"},[_c('div',{staticClass:"md:col-start-2 md:col-span-10"},[_c('div',{staticClass:"grid gap-10 md:grid-cols-2"},[_c('div',{staticClass:"texticons__content",attrs:{"data-aos":_vm.data.animation === 'slideUp'
                                ? 'slide-up'
                                : _vm.data.animation === 'slideIn'
                                ? 'fade-right'
                                : ''}},[(_vm.data.style === 'inspiration' && _vm.data.roofline)?_c('h2',{staticClass:"texticons__roofline h3",domProps:{"innerHTML":_vm._s(_vm.data.roofline)}}):_vm._e(),_vm._v(" "),(_vm.data.headline)?_c('h1',{staticClass:"texticons__headline h2",class:{ 'headline--serif': _vm.data.style === 'inspiration' },domProps:{"innerHTML":_vm._s(_vm.data.headline)}}):_vm._e(),_vm._v(" "),(_vm.data.text)?_c('div',{staticClass:"prosa",domProps:{"innerHTML":_vm._s(_vm.data.text)}}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"texticons__icons",attrs:{"data-aos":_vm.data.animation === 'slideUp'
                                ? 'fade-up'
                                : _vm.data.animation === 'slideIn'
                                ? 'fade-left'
                                : ''}},[_c('ul',{staticClass:"texticons__items grid grid-cols-2 sm:grid-cols-3"},_vm._l((_vm.iconlist),function(item,i){return _c('li',{key:(i + "{item}"),staticClass:"texticons__item"},[_c('img',{staticClass:"texticons__image",attrs:{"src":item.iconPath,"title":item.iconTitle}})])}),0),_vm._v(" "),_c('div',{staticClass:"grid sm:grid-cols-3"},[_c('div',{staticClass:"flex justify-end",class:("sm:col-span-" + _vm.colSpan)},[_c('ol',{staticClass:"texticons__notes inline-block"},_vm._l((_vm.iconlist),function(item,i){return (item.note)?_c('li',{key:("n" + i + "{item}"),staticClass:"texticons__note"},[_c('span',{staticClass:"prosa",domProps:{"innerHTML":_vm._s(item.note)}})]):_vm._e()}),0)])])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }