//
//
//
//

export default {
    name: 'ImageCopyright',
    props: ['text'],
    computed: {
        ctext() {
            return `&copy; ${this.text}`;
        },
    },
};
