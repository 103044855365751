//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'TextIcons',
    props: ['data'],
    data() {
        return {
            imagePath: this.$config.pimcoreUrl,
        };
    },
    computed: {
        iconlist() {
            return (
                this.data?.iconlist
                    ?.map((item) => {
                        const image = item?.icon?.image;

                        if (!image) return null;

                        return {
                            iconPath: `${this.imagePath}${image?.fullpath}`,
                            iconTitle: image?.metadata,
                            note: item?.note,
                        };
                    })
                    ?.filter((item) => !!item) ?? []
            );
        },
        colSpan() {
            return Math.max(0, Math.min(3, this.iconlist.length));
        },
    },
};
