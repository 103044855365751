//
//
//
//
//
//
//
//

export default {
    name: 'FooterTopButton',
    methods: {
        toTop() {
            // scroll to top (smooth)
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
            // focus logo, then blur to set tabindex position to page top
            const firstEl = document.querySelector('.header a.companylogo');
            if (firstEl) {
                firstEl.focus();
                firstEl.blur();
            }
        }
    },
};
