//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { WATCHABLE } from '../../constants/index';
import axios from 'axios';
const FileSaver = require('file-saver');

export default {
    name: 'DownloadBox',
    props: ['download', 'isModule', 'product', 'watchList', 'downloadTypes'],
    data() {
        return {
            assetPath: this.$config.pimcoreUrl,
            WATCHABLE: WATCHABLE,
        };
    },
    computed: {
        headline() {
            let h = this.download.key;
            if (this.download.title) {
                h = this.download.title;
            } else if (!this.isModule) {
                h = this.$t(`download.category.${this.download.key}`);
            }
            this.$emit('box-title', h);
            return h;
        },
        video() {
            return this.download?.items?.[0]?.video;
        },
        thumbnail() {
            return this.download?.items?.[0]?.thumbnail;
        },
        downloadItems() {
            const itemsWithCertType = this.download.items.filter((item) => item.assetCertType);
            let certDownloads = {};
            itemsWithCertType
                .map((item) => item.assetCertType)
                .forEach((type) => {
                    certDownloads[type] = itemsWithCertType.filter((item) => item.assetCertType === type);
                });
            return {
                noCertType: this.download.items.filter((item) => !item.assetCertType),
                hasCertType: certDownloads,
            };
        },
    },
    methods: {
        forceDownload(filename, url) {
            fetch(url, {
                mode: 'cors',
            })
                .then((response) => response.blob())
                .then((res) => {
                    FileSaver.saveAs(new Blob([res], { type: 'application/octet-stream' }), filename);
                });
        },
        downloadMilieuZip(data) {
            let PostData = {
                items: data,
            };

            this.$forceUpdate();
            axios
                .post(`${this.$config.pimcoreUrl}/${this.$i18n.locale}/milieu/download`, PostData, {
                    responseType: 'arraybuffer',
                })
                .then((res) => {
                    let filename = 'Milieu';

                    if (res.data) {
                        FileSaver.saveAs(new Blob([res.data], { type: 'application/zip' }), filename + '.zip');
                    }

                    this.$forceUpdate();
                })
                .catch((e) => {
                    if (typeof data !== 'undefined') {
                        data.downloading = false;
                    }
                    this.$forceUpdate();
                    alert('An error occurred please try again later.');
                });
        },
        getAssetId(item) {
            return item.assetId || item.localeAssetId;
        },
        getMilieuId(download) {
            let idArray = download.items.map((item) => item.assetId);
            return 'milieu_' + idArray.join('_');
        },
        toggleWatchListAsset(data) {
            if (this.$store.getters.getWatchList.find((o) => o.download && this.getAssetId(o.download) === this.getAssetId(data.download))) {
                this.$store.dispatch('removeFromWatchList', {
                    object: data,
                });
            } else {
                this.$store.dispatch('addToWatchList', {
                    object: data,
                });
            }
        },
        toggleWatchListMilieu(data) {
            data.download.assetId = this.getMilieuId(data.download);

            this.toggleWatchListAsset(data);
        },
        getFileType(filename) {
            return filename.split('.').length > 1 ? filename.split('.')[1].toUpperCase() : '';
        },
        getYouTube(filename) {
            if (filename) {
                return filename.split('=')[1];
            }
        },
        getFilesize(bytes) {
            let fileSize = bytes;
            if (!isNaN(bytes)) {
                let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
                if (bytes == 0) return '0 Byte';
                if (bytes == 1) return '1 Byte';
                let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
                fileSize = Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
            }

            return fileSize;
        },
    },
};
