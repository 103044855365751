import Vue from 'vue';

export default Vue.component('MissingComponent', {
    props: {
        typename: {
            type: String,
            default: null,
        },
    },
    render: function(createElement) {
        return createElement(
            'div',
            {
                style: {
                    color: 'red',
                    textAlign: 'center',
                    padding: '20px',
                    border: '1px solid red',
                },
            },
            [
                createElement(
                    'p',
                    {
                        style: {
                            fontWeight: 'bold',
                            fontSize: '20px',
                        },
                    },
                    `DEBUG: No component found for element typename: ${this.typename}`
                ),
                createElement(
                    'p',
                    {
                        style: {
                            fontSize: '14px',
                        },
                    },
                    `This message is displayed because project is in dev mode. On production this message disappears and element is ignored.`
                ),
            ]
        );
    },
});
