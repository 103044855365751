//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Multiselect from '../Multiselect.vue';

export default {
    name: 'FormGoogleMapsAutocomplete',
    components: {
        Multiselect,
    },
    props: ['placeholder', 'value', 'country', 'white', 'disabled'],
    data() {
        return {
            selected: null,
            searchGeoLocationDebounce: null,
            isLoadingLocation: false,
            options: [],
        };
    },
    watch: {
        selected(item) {
            this.$emit('input', item ? item.value : '');
        },
        value(val) {
            if (!val) {
                this.selected = val;
                this.options = [];
            }
        },
    },
    methods: {
        searchGeoLocation(val) {
            if (!val || val.length === 0) {
                return;
            }
            this.isLoadingLocation = false;
            clearTimeout(this.searchGeoLocationDebounce);
            this.searchGeoLocationDebounce = setTimeout(() => {
                this.isLoadingLocation = true;
                // eslint-disable-next-line no-undef
                const service = new google.maps.places.AutocompleteService();
                this.options = [];
                service.getPlacePredictions({ input: val, componentRestrictions: { country: this.country?.toLowerCase() } }, (predictions, status) => {
                    // eslint-disable-next-line no-undef
                    if (status !== google.maps.places.PlacesServiceStatus.OK) {
                        this.isLoadingLocation = false;
                        return;
                    }
                    predictions.forEach((prediction) => {
                        if (
                            prediction.types.some((x) => {
                                return x === 'country' || x === 'administrative_area1' || x === 'locality' || x === 'postal_code';
                            })
                        ) {
                            this.options.push({
                                text: prediction.description,
                                value: prediction.place_id,
                            });
                        }
                    });
                    this.isLoadingLocation = false;
                    this.$forceUpdate();
                });
            }, 600);
        },
        geoLocate(e) {
            e.stopPropagation();
            e.preventDefault();
            this.$refs.select.querySelector('input').blur();

            if (navigator.geolocation) {
                this.isLoadingLocation = true;
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        // eslint-disable-next-line no-undef
                        const geocoder = new google.maps.Geocoder();
                        geocoder.geocode(
                            {
                                location: { lat: parseFloat(position.coords.latitude), lng: parseFloat(position.coords.longitude) },
                            },
                            (responses, status) => {
                                if (status === 'OK' && responses.length) {
                                    this.selected = {
                                        text: responses[0].formatted_address,
                                        value: responses[0].place_id,
                                    };
                                    this.$emit('geoLocate', responses[0]);
                                }
                                this.isLoadingLocation = false;
                            }
                        );
                    },
                    () => {
                        alert('Please activate geo location in your browser to use this function.');
                    }
                );
            }
        },
        getDeeplinkLocation() {
            if (process.client) {
                const url_string = window.location.href;
                const url = new URL(url_string);
                const place_id = url.searchParams.get('place_id');
                if (place_id) {
                    const geocoder = new google.maps.Geocoder();
                    geocoder.geocode({ placeId: place_id }, (results, status) => {
                        if (status === 'OK') {
                            if (results[0]) {
                                this.selected = { text: results[0].formatted_address, value: place_id };
                            }
                        }
                    });
                }
            }
        },
    },
    mounted() {
        this.$refs.select.querySelector('.multiselect__select').addEventListener('click', this.geoLocate);
        this.$on('hook:beforeDestroy', () => {
            this.$refs.select.querySelector('.multiselect__select').removeEventListener('click', this.geoLocate);
        });
        setTimeout(() => {
            this.getDeeplinkLocation();
        }, 50);
        //fix vue multiselect autocomplete="nope" bug
        this.$refs.multiselectRef.$refs.search.setAttribute('autocomplete', 'off');
    },
};
