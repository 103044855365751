//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'CtaTeaser',
    props: ['data'],
    data() {
        return {
            assetPath: this.$config.pimcoreUrl,
        };
    },
    computed: {
        link() {
            const link = this.data?.link;

            if (!link.path) return null;

            if (link.isDownloadLink) {
                link.path = `${this.assetPath}${link.path}`;
            }

            return link;
        },
    },
};
