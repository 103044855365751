//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
    name: 'ProductDataTable',
    props: ['tableData'],
    data() {
        return {
            noFold: false,
            expanded: false,
            tableKeys: null,
        };
    },
    watch: {
        expanded(val) {
            const aboveFold = this.$refs.table.querySelector('thead').getBoundingClientRect().height + this.$refs.table.querySelector('tbody tr:first-child').getBoundingClientRect().height + 2;
            const height = (val) ? this.$refs.table.getBoundingClientRect().height : aboveFold;
            document.documentElement.style.setProperty('--prodDetailTableHeight', `${height}px`);
        }
    },
    computed: {
        ...mapGetters([
            'getStoredProduct',
            'getStoredProductVariant',
        ]),
        sortedVariants() {
            let variants = [].concat(this.getStoredProduct?.variants);
            let from = variants.findIndex((v) => v.id === this.getStoredProductVariant.id);
            let to = 0;
            variants.splice(to, 0, variants.splice(from, 1)[0]);
            return variants;
        },
        variant() {
            return this.getStoredProductVariant;
        }
    },
    mounted() {
        this.expanded = false;
        try {
            this.tableKeys = [];
            this.tableData.forEach((data) => {
                Object.keys(data).forEach((d) => {
                    if (data[d] !== null && this.tableKeys.indexOf(d) === -1) {
                        this.tableKeys.push(d);
                    }
                });
            });
            this.noFold = (this.tableData.length === 1);
        } catch(e) {
            console.warn(e);
        }
    },
    methods: {
        cellContent(string) {
            return parseInt(string, 10) || '&nbsp;';
        }
    }
};
