//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'News',
    props: ['data'],
};
