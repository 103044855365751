//
//
//
//
//
//
//
//

import TextBlock from '../Partials/TextBlock.vue';
export default {
    name: 'TextCentered',
    components: {TextBlock},
    props: ['data'],
};
