//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'Reference',
    props: ['data'],
    data() {
        return {
            imagePath: this.$config.pimcoreUrl,
            infoKeys: ['owner', 'builder', 'architect', 'interiordesigner', 'rooms', 'opening', 'products'],
        };
    },
    computed: {
        subline() {
            const { city, country } = this.data;

            const parts = [city];
            if (country && country !== 'other') {
                parts.push(this.getCountryTranslation(country));
            }

            return parts.filter((o) => !!o).join(', ');
        },
    },
    methods: {
        getCountryTranslation(code) {
            const country = !code ? 'other' : code.toLowerCase();
            return this.$t(`country.${country}`);
        },
        getKeyTranslation(key) {
            return this.$t(`reference.info.${key}`) + `:`;
        },
        getKeyValue(key) {
            let infos = [];
            if (key === 'products') {
                Object.values(this.data?.[key] ?? []).forEach((value) => {
                    if (value.name) {
                        infos.push(value.name);
                    }
                });

                infos = infos.sort((a, b) => a.localeCompare(b));
            } else {
                infos.push(this.data?.[key]);
            }
            return infos.filter((o) => !!o).join(', ');
        },
    },
};
