//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * Important Notice:
 * https://developers.google.com/youtube/player_parameters#release_notes_08_23_2018
 */
import Posterimage from './Posterimage.vue';
if (process.client) {
    require('mediaelement');
    require('mediaelement/build/lang/de');
    require('mediaelement/build/lang/it');
    require('mediaelement/build/lang/es');
    require('mediaelement/build/lang/nl');
    require('mediaelement/build/lang/fr');
    require('mediaelement/build/lang/zh');
    require('mediaelement/build/mediaelementplayer.css');
}
export default {
    name: 'Mediaplayer',
    components: {Posterimage},
    props: {
        video: {
            type: Object,
            default: null
        },
        controls: {
            type: Boolean,
            default: true
        },
        poster: {
            type: Object,
            default: null
        },
        autoplay: {
            type: Boolean,
            default: false
        },
        loop: {
            type: Boolean,
            default: false
        },
        muted: {
            type: Boolean,
            default: false
        },
        stretching: {
            type: String,
            default: 'responsive'
        },
    },
    data() {
        return {
            mediaPath: this.$config.pimcoreUrl,
            player: null,
            played: false,
            isTouchDevice: false,
            error: false,
            showPoster: (this.poster && this.poster.image && this.autoplay === false),
            ytPlayer: null,
            id: null,
            src: null,
            ap: false,
        };
    },
    mounted() {
        if (process.client) {
            this.isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints;
            this.id = `ytPlayer${this._uid}`;
            this.ap = this.autoplay;
            if (this.poster) {
                this.ap = false;
            }

            if (this.embedd()) {
                this.$nuxt.$on('onYouTubePlayerAPIReady', () => {
                    window.addEventListener("ucEvent", (e) => {
                        if(e.detail && e.detail.event == "consent_status") {
                            this.initYT();
                        }
                    });
                    this.initYT();
                });
                if (!Array.from(document.scripts).find(item => item.scr === 'https://www.youtube.com/iframe_api')) {
                    const tag = document.createElement('script');
                    tag.src = 'https://www.youtube.com/iframe_api';
                    const firstScriptTag = document.getElementsByTagName('script')[0];
                    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
                    window.onYouTubePlayerAPIReady = () => {
                        this.$nuxt.$emit('onYouTubePlayerAPIReady');
                    }
                } else {
                    this.initYT();
                }
                return;
            }

            const mediaElement = this.$refs.mediaElement;
            if (!mediaElement) {
                return;
            }

            mediaElement.controls = this.controls;
            mediaElement.muted = this.muted;

            if (!this.controls) {
                this.ap = true;
            }

            if (this.ap) {
                mediaElement.muted = 'muted';
                mediaElement.autoplay = 'autoplay';
            }

            let options = {};
            if (this.controls) {
                options = {
                    //stretching: this.stretching,
                    enableAutosize: false,
                    audioVolume: 'vertical',
                    features: ['playpause', 'current', 'progress', 'duration', 'volume', 'fullscreen'],
                    success: (media, node, instance) => this.onSuccess(media, node, instance),
                    error: (media, node) => this.onError(media, node),
                }
            } else {
                options = {
                    features: [],
                };
            }

            mejs.i18n.language(this.$i18n.locale);
            this.player = new MediaElementPlayer(mediaElement, options);

            window.addEventListener('resize', this.onResize);
            this.onResize();
        }
    },
    methods: {
        onResize() {
            const w = this.$el.getBoundingClientRect().width;
            this.setSize(w, w / 1.7777777778);
        },
        embedd() {
            // should switch video.data.type: youtube || vimeo || dailymotion
            this.src = (this.video.data && this.video.data.id && this.video.type === 'youtube') ? `https://www.youtube-nocookie.com/embed/${this.video.data.id}?enablejsapi=1&rel=0&modestbranding=1&${this.controls ? 'controls=1':'controls=0'}&${this.ap ? 'autoplay=1&mute=1' : 'autoplay=0&mute=0'}` : null;
            return this.src;
        },
        onError() {
            this.error = true;
        },
        onSuccess(media, node, instance) {
            media.addEventListener('play', this.onPlay, false);
            if (this.ap) {
                instance.play();
            }
        },
        onPlay() {
            this.played = true;
            this.$nuxt.$emit('mediaplayer.play', {target:this.$el, vueInstance:this});
        },
        setSize(width, height) {
            this.player.setPlayerSize(width, height);
        },
        pause() {
            this.player.pause();
        },
        onClickPoster() {
            this.showPoster = false;
            this.$forceUpdate();

            if (this.embedd()) {
                if (this.ytPlayerTarget) {
                    this.ytPlayerTarget.playVideo();
                    this.ytPlayerTarget.unMute();
                } else {
                    this.initYT(true);
                }
            } else {
                this.player.play();
            }
        },
        initYT(playOnReady) {
            let that = this;
            this.ytPlayer = new YT.Player(this.id, {
                events: {
                    'onReady': function(event) {
                        const target = event.target;
                        that.ytPlayerTarget = target;
                        if (playOnReady) {
                            target.playVideo();
                            target.unMute();
                        }
                    }
                }
            });

            this.$nuxt.$on('stopMediaplayer', () => {
                if (this.ytPlayer && this.ytPlayer.stopVideo) {
                    this.ytPlayer.stopVideo();
                }
            });
        }
    },
};
