//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'MerchantStage',
    props: ['data'],
    methods: {
        googleMapLoaded(googleMap) {
            const location = {
                lat: this.data.lat,
                lng: this.data.lng,
            };
            googleMap.setZoom(14);
            googleMap.setCenter(location);
            const marker = new google.maps.Marker({
                position: location,
                icon: {
                    url: '/pin-secondary.svg',
                    scaledSize: new google.maps.Size(60, 60), // scaled size
                    origin: new google.maps.Point(0, 0), // origin
                    anchor: new google.maps.Point(30, 60), // anchor
                },
                googleMap,
            });
            marker.setMap(googleMap);
        },
    },
};
