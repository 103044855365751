import { render, staticRenderFns } from "./PressReleaseFooter.vue?vue&type=template&id=47f98627&"
import script from "./PressReleaseFooter.vue?vue&type=script&lang=js&"
export * from "./PressReleaseFooter.vue?vue&type=script&lang=js&"
import style0 from "./PressReleaseFooter.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DownloadBox: require('/usr/src/app/components/Partials/DownloadBox.vue').default,ContactBox: require('/usr/src/app/components/Partials/Contact/ContactBox.vue').default})
