import Vue from 'vue';
import { ENRICHED_NEWS, ENRICHED_PRESS_RELEASE, ENRICHED_REFERENCE } from '../constants/index';
import News from './Partials/News/News.vue';
import PressRelease from './Partials/PressRelease/PressRelease.vue';
import PressReleaseFooter from './Partials/PressRelease/PressReleaseFooter.vue';
import ReferenceStage from './Partials/Reference/ReferenceStage.vue';
import ReferenceTeaserSlider from './Partials/Reference/ReferenceTeaserSlider.vue';

const componentMap = {
    pre: {
        [ENRICHED_PRESS_RELEASE]: PressRelease,
        [ENRICHED_REFERENCE]: ReferenceStage,
        [ENRICHED_NEWS]: News,
    },
    post: {
        [ENRICHED_PRESS_RELEASE]: PressReleaseFooter,
        [ENRICHED_REFERENCE]: ReferenceTeaserSlider,
    },
};

export default Vue.component('PageRelated', {
    props: {
        type: {
            type: String,
            required: true,
            validator: (v) => ['pre', 'post'].includes(v),
        },
        relatedObject: {
            type: Object,
            required: true,
        },
    },
    render: function(createElement) {
        const component = componentMap[this.type]?.[this.relatedObject.classname];

        if (!component) return null;

        return createElement(component, {
            props: { data: this.relatedObject },
        });
    },
});
