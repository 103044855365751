//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Swiper, { Navigation, Pagination, Scrollbar } from 'swiper';
Swiper.use([Navigation, Pagination, Scrollbar]);

export default {
    name: 'ProductRelatedTeaserSlider',
    props: ['data'],
    data() {
        return {
            productDataExists: false,
            productsLoading: false,
            tabFilter: false,
            swiper: null,
            products: [],
            headline: null,
            tab: null,
            text: null,
            extras: false,
            links: [],
            items: [],
            categories: [],
        };
    },
    computed: {
        accessoryCategories() {
            const categoriesOrdered = [];
            const categories = [...new Set(this.items?.map((item) => { return {canonical: item?.cannonical_accessoryCategory, localized: item?.accessoryCategory}}) ?? [])].filter((o) => !!o);
            const accessoryCategoryMap = [
                "Ab- und Überlauf",
                "Einbaumontagehilfe",
                "Ausstattung / Oberfläche",
                "Pflege",
                "Rahmengestell",
                "Sonderlösung",
                "Whirlsystem",
            ];

            const getLocalizedCategoryName = (canonical, catArray) => {
                for (var i=0; i < catArray.length; i++) {
                    if (catArray[i].canonical === canonical) {
                        return catArray[i].localized;
                    }
                }
            };

            // order categories by order of accessoryCategoryMap
            for (let i = 0; i < accessoryCategoryMap.length; i++) {
                if (categories[i]) {
                    const localizedCategoryName = getLocalizedCategoryName(accessoryCategoryMap[i], categories);
                    if (localizedCategoryName) {
                        categoriesOrdered.push( localizedCategoryName );
                    }
                }
            }

            return categoriesOrdered.length <= 1 ? false : categoriesOrdered;
        },

    },
    methods: {
        /**
         * Filter slides by key string
         * @param keyString
         */
        filterSlidesByKey(keyString) {
            this.tabFilter = this.tabFilter === keyString ? false : keyString;
            setTimeout(()=>{
                this.swiper.update()
                setTimeout(()=>{
                    this.swiper.update()
                }, 50)
            }, 50)
        },
        showNavigation(swiper) {
            const { isBeginning, isEnd } = swiper;
            if(this.$refs.swiperContainer) {
                if (isBeginning && isEnd) {
                    this.$refs.swiperContainer.classList.add('product-related-teaser-slider__slider--no-navigation');
                } else {
                    this.$refs.swiperContainer.classList.remove('product-related-teaser-slider__slider--no-navigation');
                }
            }
        }
    },
    async mounted() {
        this.items = this.data?.items;
        this.headline = this.data?.headline;
        this.extras = this.data?.extras;
        this.tab = this.data?.tab;
        this.categories = this.data?.categories;
        this.text = this.data?.text;
        this.links = this.data?.links;

        const _this = this;
        const newSwiperElm = this.$refs.swiperSlide
        const gallery = new Swiper(newSwiperElm, {
            spaceBetween: 5,
            slidesPerView: 1.2,
            loop: false,
            autoHeight: false,
            observer: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            speed: 750,
            a11y: true,
            breakpoints: {
                768: {
                    spaceBetween: 30,
                    slidesPerView: 3.2,
                },
            },
            navigation: {
                nextEl: '.product-related-teaser-slider__button--next',
                prevEl: '.product-related-teaser-slider__button--prev',
                disabledClass: 'product-related-teaser-slider__button--disabled',
                dragSize: 100,
            },
            pagination: {
                el: '.product-related-teaser-slider__pagination',
                type: 'fraction',
            },
            scrollbar: {
                el: '.product-related-teaser-slider__scrollbar',
                dragClass: 'product-related-teaser-slider__scrollbar--drag',
                hide: false,
            },
            on: {
                init: function() {
                    _this.showNavigation(this);
                },
                resize: function() {
                    _this.showNavigation(this);
                },
                observerUpdate: function() {
                    _this.showNavigation(this);
                },
                update: function() {
                    _this.showNavigation(this);
                },
            },
        });

        this.swiper = gallery

        this.$nuxt.$on('changeTab', () => {
            this.$nextTick(() => {
                this.swiper.update();
            });
        });

        this.productDataExists = this.data.items.find(o => o?.name);
        if (this.productDataExists) {
            this.products = this.data.items;
            this.$nextTick(() => {
                this.swiper.update();
            });
            return;
        }
        this.productsLoading = true;
        for (let item of this.data.items) {
            let product = null;
            if (item.__typename === 'object_Product') {
                product = await this.$nuxt.context.app.api.product.getProductById(item.id);
            } else {
                product = await this.$nuxt.context.app.api.product.getProductByVariantId(item.id);
                product.variants = product.variants.filter(variant => variant.id !== item.id);
            }

            if (product) {
                product.type = item.__typename
                this.products.push(product);
                this.swiper.update();
            }
        }
        this.$nextTick(() => {
            this.swiper.update();
        });
        this.productsLoading = false;
    }
};
