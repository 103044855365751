//
//
//
//
//
//
//
//
//
//
//

import StagePressRelease from './StagePressRelease.vue';

export default {
    name: 'PressRelease',
    components: { StagePressRelease },
    props: ['data'],
};
