export function includeFrameInPrice(extKey) {
    
    if (!extKey) return false;

    const productsWithPrice = [
    // Lux Shape Standspiegel 229532
    "BM_1312",
    // Lux Shape Handtuchhalter 133519
    "BM_1314",
    // Lux Shape Hocker 229521
    "BM_1313",
    // Lux Shape Gestell für Badewanne 133514
    "BM_1303",
    // Lux Shape Gestell für Waschtische 133511
    "BM_1302",
    ]
    if (productsWithPrice.includes(extKey)) return true;
    return false;
}