// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/mejs/mejs-controls-bette.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".mediaplayer .uc-embedding-container{position:absolute}.mediaplayer .mejs__video,.mediaplayer video{width:100%!important;height:100%!important}.mediaplayer video{position:relative;z-index:-1}.mediaplayer__message{position:relative;--tw-bg-opacity:1;background-color:rgba(227,227,227,var(--tw-bg-opacity));padding-bottom:56.3%;text-align:center}.mediaplayer__message>*{position:absolute;display:inline-block;text-align:center;max-width:100%;top:50%;left:50%;transform:translate(-50%,-50%)}.mediaplayer__mediacontainer{position:relative;padding-bottom:56.3%}.mediaplayer__media{position:absolute;top:0;left:0;height:100%;width:100%}.mediaplayer__poster{overflow:hidden}.mediaplayer__media,.mediaplayer__poster{position:absolute;top:0;left:0;height:100%;width:100%}.mediaplayer__poster-image{-o-object-fit:cover;object-fit:cover}.mediaplayer__poster-button{width:80px;height:80px;position:absolute;top:50%;left:50%;transform:scale(1.6) translate(-50%,-50%);background-position:0 -39px;transform-origin:left top;transition:transform .32s cubic-bezier(.4,0,.2,1)}.mediaplayer__poster-button:hover{transform:scale(1.75) translate(-50%,-50%);background-position:-80px -39px}.mediaplayer .fadeMediaPlayer-enter-active,.mediaplayer .fadeMediaPlayer-leave-active{transition:opacity .32s}.mediaplayer .fadeMediaPlayer-enter,.mediaplayer .fadeMediaPlayer-leave-to{opacity:0}.mediaplayer__poster-button,.mejs__button>button,.mejs__overlay-button,.mejs__overlay-loading-bg-img{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.mejs__overlay-button{pointer-events:all;transform:scale(1.6);transition:transform .32s cubic-bezier(.4,0,.2,1)}.mejs__overlay-button:hover{transform:scale(1.75)}.mejs__overlay{pointer-events:none}", ""]);
// Exports
module.exports = exports;
