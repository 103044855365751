//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'ReferenceTeaserSliderImage',
    props: ['reference', 'link', 'image', 'headlineType'],
    computed: {
        href() {
            const relation = this.reference?.relation;
            if (!relation || !relation?.id || !relation?.visible) return null;
            return this.$getLocalizedSlug(relation.id);
        },
        imageData() {
            return this.$mapImageData(this.image);
        },
        subline() {
            const { city, country } = this.reference;

            const parts = [city];
            if (country && country !== 'other') {
                parts.push(this.getCountryTranslation(country));
            }

            return parts.filter((o) => !!o).join(', ');
        },
    },
    methods: {
        getCountryTranslation(code) {
            const country = !code ? 'other' : code.toLowerCase();
            return this.$t(`country.${country}`);
        },
    },
};
