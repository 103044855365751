//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IndentedContent from '../Partials/IndentedContent.vue';
import DownloadBox from '../Partials/DownloadBox.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'Productdownloads',
    components: { DownloadBox, IndentedContent },
    props: ['initVariant', 'mediacenter', 'initProduct', 'downloadTypes', 'showBoxHeader'],
    methods: {
        headline(download) {
            let h = download.key;
            let key = download.key || download.localeAssetKey || download.assetKey;
            if (key === 'A_PIM_IFC_Daten' || key === 'A_PIM_Revit_Daten') {
                key = 'bim';
            }
            if (key === 'A_PIM_Haupt-Milieubild' || key === 'A_PIM_Weitere-Milieubilder') {
                key = 'milieu';
            }
            if (download.title) {
                h = download.title;
            } else {
                h = this.$t(`download.category.${key}`);
            }
            return h;
        },
        filterItems(downloadTypes, allData) {
            const data = structuredClone(allData);

            if (downloadTypes && downloadTypes.length) {
                for (const [key, boxItem] of Object.entries(data)) {
                    data[key].items = boxItem.items.filter((item) => {
                        const title = this.headline(item);
                        return downloadTypes.some((i) => i.name === title);
                    });
                    if (data[key].items.length === 0) {
                        delete data[key];
                    }
                }
            }
            return data;
        },

        getVariantLink(product, variant) {
            let href = this.$localeSlug(this.getStoredSiteProperties.product_page);
            href = href + '/' + product?.slug;
            return `${href}`;
        },
    },
    computed: {
        ...mapGetters(['getStoredSiteProperties', 'getStoredProductVariant', 'getStoredProduct']),
        variant() {
            return this.initVariant ? this.initVariant : this.getStoredProductVariant;
        },
        product() {
            return this.initProduct ? this.initProduct : this.getStoredProduct;
        },
        // get planningdata sorted by asset keys
        planningdata() {
            let items = {};
            const today = new Date();

            for (const i in this.variant?.downloads) {
                const item = this.variant?.downloads[i];

                if (item.validFrom && new Date(item.validFrom) >= today) {
                    continue;
                }
                if (item.validUntil && new Date(item.validUntil) <= today) {
                    continue;
                }
                let key = item.localeAssetKey;

                // Diplay both files in the same download box
                if (key === 'A_PIM_IFC_Daten' || key === 'A_PIM_Revit_Daten') {
                    key = 'bim';
                }

                if (key !== 'A_PIM_Markenzertifikate' && key !== 'A_PIM_Print_Unterlagen_Modell' && item.localeAssetKey !== 'A_PIM_GLTF') {
                    if (!items[key]) {
                        items[key] = {
                            key: key,
                            items: [],
                        };
                    }
                    items[key].items.push(item);
                }
            }

            for (const i in this.variant?.localeDownloads) {
                const item = this.variant.localeDownloads[i];
                if (item.validFrom && new Date(item.validFrom) >= today) {
                    continue;
                }
                if (item.validUntil && new Date(item.validUntil) <= today) {
                    continue;
                }

                if (item.localeAssetKey === 'A_PIM_Schnittzeichnungen' || (item.localeAssetKey === 'A_PIM_Print_Unterlagen_Modell' && item.localeAssetType === 'Planungshinweis')) {
                    if (!items[item.localeAssetKey]) {
                        items[item.localeAssetKey] = {
                            key: item.localeAssetKey,
                            title: item.localeAssetType,
                            items: [],
                        };
                    }

                    items[item.localeAssetKey].items.push({
                        localeAssetKey: item.localeAssetKey,
                        localeAssetId: item.localeAssetId,
                        filePath: item.localeFilePath,
                    });
                }
            }

            items = this.filterItems(this.downloadTypes, items);
            return items;
        },
        // get productdata sorted by asset keys
        productdata() {
            const today = new Date();
            let items = {};
            for (const i in this.variant?.localeDownloads) {
                const item = this.variant.localeDownloads[i];
                if (item.validFrom && new Date(item.validFrom) >= today) {
                    continue;
                }
                if (item.validUntil && new Date(item.validUntil) <= today) {
                    continue;
                }
                if (item.localeAssetKey !== 'A_PIM_Print_Unterlagen' && item.localeAssetKey !== 'A_PIM_Print_Unterlagen_Modell' && item.localeAssetKey !== 'A_PIM_Schnittzeichnungen') {
                    if (!items[item.localeAssetKey]) {
                        items[item.localeAssetKey] = {
                            key: item.localeAssetKey,
                            items: [],
                        };
                    }

                    items[item.localeAssetKey].items.push({
                        assetKey: item.localeAssetKey,
                        assetId: item.localeAssetId,
                        assetCertType: item.localeAssetCertType,
                        filePath: item.localeFilePath,
                    });
                }
            }
            items = this.filterItems(this.downloadTypes, items);
            return items;
        },
        // get assets sorted by asset keys
        assets() {
            let items = {};
            this.variant?.images?.forEach((item) => {
                if (item.assetKey === 'A_PIM_Bild') {
                    if (!items[item.assetKey]) {
                        items[item.assetKey] = {
                            key: item.assetKey,
                            items: [],
                        };
                    }
                    items[item.assetKey].items.push({
                        assetKey: item.assetKey,
                        assetId: item.derivateId,
                        thumbnail: item.gallery.srcset[0].resolutions[0].url,
                        filePath: {
                            fullpath: item.lightbox.fullpath,
                            filename: item.lightbox.filename,
                            filesize: item.lightbox.filesize,
                        },
                    });
                }
            });

            this.product?.images?.forEach((item) => {
                let key = 'milieu';
                if (item.assetKey === 'A_PIM_Haupt-Milieubild' || item.assetKey === 'A_PIM_Weitere-Milieubilder') {
                    if (!items[key]) {
                        items[key] = {
                            key: key,
                            items: [],
                        };
                    }
                }

                if (item.assetKey === 'A_PIM_Haupt-Milieubild') {
                    items[key].items.unshift({
                        assetKey: item.assetKey,
                        assetId: item.derivateId,
                        thumbnail: item.gallery.srcset[0].resolutions[0].url,
                        filePath: {
                            fullpath: item.gallery.fullpath,
                            filename: item.gallery.filename,
                        },
                    });
                }

                if (item.assetKey === 'A_PIM_Weitere-Milieubilder') {
                    items[key].items.push({
                        assetKey: item.assetKey,
                        assetId: item.derivateId,
                        thumbnail: item.gallery.srcset[0].resolutions[0].url,
                        filePath: {
                            fullpath: item.gallery.fullpath,
                            filename: item.gallery.filename,
                        },
                    });
                }
            });

            if (this.product?.videoUrls) {
                items['video'] = {
                    key: 'video',
                    items: [],
                };

                this.product.videoUrls.forEach((url) => {
                    items['video'].items.push({
                        assetKey: 'video',
                        assetId: 'id',
                        video: url,
                    });
                });
            }

            items = this.filterItems(this.downloadTypes, items);
            return items;
        },
        // get brochures sorted by asset keys
        brochures() {
            let items = [];
            const today = new Date();
            this.variant?.localeDownloads?.forEach((item) => {
                if ((item.validFrom && new Date(item.validFrom) >= today) || (item.validUntil && new Date(item.validUntil) <= today)) {
                    return;
                }
                if (item.localeAssetKey === 'A_PIM_Print_Unterlagen' || (item.localeAssetKey === 'A_PIM_Print_Unterlagen_Modell' && item.localeAssetType !== 'Planungshinweis')) {
                    items.push({
                        key: item.localeAssetKey,
                        title: this.$t(`download.category.brochures.${item.localeAssetType?.toLowerCase()}`),
                        items: [
                            {
                                assetKey: item.localeAssetKey,
                                assetType: item.localeAssetType,
                                assetId: item.localeAssetId,
                                filePath: item.localeFilePath,
                            },
                        ],
                    });
                }
            });

            for (const i in this.variant?.downloads) {
                const item = this.variant?.downloads[i];
                let key = item.localeAssetKey;

                if (item.validFrom && new Date(item.validFrom) >= today) {
                    continue;
                }
                if (item.validUntil && new Date(item.validUntil) <= today) {
                    continue;
                }

                if (key !== 'A_PIM_Markenzertifikate' && key === 'A_PIM_Print_Unterlagen_Modell') {
                    items.push({
                        key: item.localeAssetKey,
                        items: [
                            {
                                assetKey: item.localeAssetKey,
                                assetId: item.localeAssetId,
                                filePath: item.filePath,
                            },
                        ],
                    });
                }
            }
            items = this.filterItems(this.downloadTypes, items);
            return items;
        },
    },
};
