//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import Tab from '~/components/Tab/Tab.vue';
import Tabs from '~/components/Tab/Tabs.vue';
import ProductTeaserSelection from '../Content/ProductTeaserSelection.vue';
import ProductRelatedTeaserSlider from '../Product/ProductRelatedTeaserSlider.vue';

export default {
    name: 'TabModule',
    components: {
        Tab,
        Tabs,
        ProductTeaserSelection,
        ProductRelatedTeaserSlider
    },
    props: ['data'],
    data() {
        return {
            activeIndex: 0
        };
    },
}
