//
//
//
//
//
//
//
//



export default {
    name: "Iframe",
    props: ['data'],
}
