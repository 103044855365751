//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Swiper, { Navigation, Pagination, Thumbs } from 'swiper';
Swiper.use([Navigation, Pagination, Thumbs]);

import ImageCopyright from '../Partials/ImageCopyright.vue';

export default {
    name: 'ProductGallery',
    components: { ImageCopyright },
    props: ['data', 'videos'],
    computed: {
        images() {
            let data = this.data?.map((image) => this.$mapImageData(image?.gallery));
            if (this.videos) {
                try {
                    const videos = this.videos.map((url) => ({ url: `https://www.youtube-nocookie.com/embed/${(new URL(url)).searchParams.get("v")}`, videoId: (new URL(url)).searchParams.get("v") }));
                    data = [...data, ...videos];
                } catch (e) {
                    console.error(e);
                }
            }
            return data;
        },
        thumbs() {
            let data = this.data?.map((image) => this.$mapImageData(image?.thumb));
            if (this.videos) {
                try {
                    const videos = this.videos.map((url) => ({ url: `https://www.youtube-nocookie.com/embed/${(new URL(url)).searchParams.get("v")}`, videoId: (new URL(url)).searchParams.get("v") }));
                    data = [...data, ...videos];
                } catch (e) {
                    console.error(e);
                }
            }
            return data;
        },
    },
    mounted() {
        const galleryThumbs = new Swiper('.product-gallery-thumbs--slide', {
            spaceBetween: 10,
            slidesPerView: 5,
            loop: false,
            observer: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            navigation: {
                nextEl: '.product-gallery-thumbs__button--next',
                prevEl: '.product-gallery-thumbs__button--prev',
                disabledClass: 'product-gallery-thumbs__button--disabled',
            },
            on: {
                observerUpdate: () => {
                    this.$nuxt.$lightbox.reload();
                },
                click: () => {
                    this.$nuxt.$emit('stopMediaplayer');
                },
            },
        });

        const galleryTop = new Swiper('.product-gallery-top--slide', {
            a11y: true,
            slidesPerView: 1,
            speed: 750,
            observer: true,
            pagination: {
                el: '.product-gallery-top__pagination',
                type: 'fraction',
            },
            navigation: {
                nextEl: '.product-gallery-top__button--next',
                prevEl: '.product-gallery-top__button--prev',
                disabledClass: 'product-gallery-top__button--disabled',
            },
            thumbs: {
                slideThumbActiveClass: 'product-gallery-thumbs__slide--active',
                thumbsContainerClass: 'product-gallery-thumbs__slide',
                swiper: galleryThumbs,
            },
        });
    },
};
