//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProductTeaser from '../Partials/ProductTeaser/ProductTeaser.vue';
export default {
    components: { ProductTeaser },
    name: 'ProductTeaserSelection',
    props: ['data'],
    data() {
        return {
            products: [],
        };
    },
    async fetch() {
        for (let item of this.data.items) {
            let product = null;
            if (item.__typename === 'object_Product') {
                product = await this.$nuxt.context.app.api.product.getProductById(item.id);
                product.variants = product.variants.map(variant => ({
                    prices: variant.prices,
                    negativeTerritories: variant.negativeTerritories,
                    kMat: variant.kMat,
                    externalKey: variant.externalKey,
                    width: variant.width,
                    height: variant.height,
                    length: variant.length,
                }));
                product.accessories = [];
                product.extras = [];
            } else {
                product = await this.$nuxt.context.app.api.product.getProductByVariantId(item.id);
                product.variants = product.variants.filter(variant => variant.id !== item.id);
                product.accessories = [];
                product.extras = [];
            }

            if (product) {
                this.products.push({
                    product: product,
                    type: item.__typename,
                });
            }
        }
    }
}
