//
//
//
//
//
//
//
//

export default {
    name: 'IndentedContent',
    props: ['narrow', 'nomargin'],
};
