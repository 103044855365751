import { render, staticRenderFns } from "./Tab.vue?vue&type=template&id=5d32453f&"
import script from "./Tab.vue?vue&type=script&lang=js&"
export * from "./Tab.vue?vue&type=script&lang=js&"
import style0 from "./Tab.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TextIcons: require('/usr/src/app/components/Content/TextIcons.vue').default,TextImage: require('/usr/src/app/components/Content/TextImage.vue').default,TextVideo: require('/usr/src/app/components/Content/TextVideo.vue').default,TextCentered: require('/usr/src/app/components/Content/TextCentered.vue').default,ImageTeaser: require('/usr/src/app/components/Content/ImageTeaser.vue').default,ProductTeaserSelection: require('/usr/src/app/components/Content/ProductTeaserSelection.vue').default,ProductRelatedTeaserSlider: require('/usr/src/app/components/Product/ProductRelatedTeaserSlider.vue').default,Tab: require('/usr/src/app/components/Content/Tab.vue').default,Tabs: require('/usr/src/app/components/Tab/Tabs.vue').default})
