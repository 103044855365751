//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'vue-tabs-container',

    props: {
        activeTab: {
            type: Number,
            default: null,
        },
        defaultIndex: {
            type: Number,
            default: 0,
        },
        transitionName: {
            type: String,
            default: '',
        },
        ariaLabelValue: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            activeIndex: this.defaultIndex,
            items: [],
        };
    },

    methods: {
        getTabs() {
            return this.$slots.default ? this.$slots.default.filter(({ tag }) => tag && tag.includes('vue-tabs-item')) : [];
        },
        getTabsLength() {
            return this.getTabs().length;
        },
        switchTab(index) {
            this.activeIndex = index;

            this.$emit('activeIndex', this.activeIndex);
            this.$nuxt.$emit('changeTab');
            this.focusActiveTab();
        },
        focusActiveTab() {
            this.items.forEach((tab, index) => {
                if (index !== this.activeIndex) {
                    tab.instance.$data.active = false;
                }
            });
            this.items[this.activeIndex].instance.$data.active = true;
        },
        showNextTab() {
            const nextIndex = this.activeIndex + 1;

            this.switchTab(nextIndex % this.getTabsLength());
        },
        showPrevTab() {
            const prevIndex = this.activeIndex - 1 < 0 ? this.getTabsLength() - 1 : this.activeIndex - 1;

            this.switchTab(prevIndex);
        },
        isTabActive(index) {
            return this.activeIndex === index;
        },
        getTabClasses(index) {
            return ['vue-tabs__nav-item', { '--active': this.isTabActive(index) }];
        },
    },
    mounted() {
        this.getTabs().forEach((item) => {
            const instance = item.componentInstance;
            this.items.push({
                instance,
            });
        });
        this.items[0].instance.$data.active = true;
        if (this.activeTab) {
            this.switchTab(this.activeTab);
        }
    },
};
