//
//
//
//
//
//
//

export default {
    name: 'Merchant',
    props: ['merchant'],
};
