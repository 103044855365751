//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'StagePressRelease',
    props: ['data'],
    data() {
        return {
            pressData: {
                headline: this.$t('press.headline'),
                image: this.data?.stageimage,
                imageMobile: this.data?.stageimageMobile,
            },
        };
    },
    methods: {
        releaseDate(date) {
            const newdate = new Date(date);
            const formatter = new Intl.DateTimeFormat(this.$i18n.locale, {
                day: '2-digit',
                month: 'long',
                year: 'numeric',
            }).format(newdate);
            return formatter;
        },
    },
};
