//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { required } from 'vuelidate/lib/validators';
import Multiselect from '../Multiselect.vue';

export default {
    name: 'FormAutocomplete',
    components: {
        Multiselect,
    },
    props: [
        'placeholder',
        'value',
        'options',
        'white',
        'label',
        'required',
        'id',
        'error',
        'searchable',
        'trackedBy',
        'optionLabel',
    ],
    data() {
        return {
            items: [],
            selected: null,
            searchGeoLocationDebounce: null,
            isLoadingLocation: false,
            inputPlaceholder: null,
        };
    },
    watch: {
        selected(item) {
            this.$emit('input', item ? (item.constructor.name === 'Object' ? item.value : item) : '');
            this.inputPlaceholder = item.constructor.name === 'Object' ? item.text : item;
        },
        value(val) {
            this.handleValue(val);
        },
    },
    methods: {
        handleValue(val) {
            if (this.options.constructor.name === 'Object') {
                this.items = Object.values(this.options);
                this.selected = Object.values(this.options)?.find((o) => o === val) || '';
            } else {
                this.items = this.options;
                this.selected = this.options?.find((o) => o.value === val) || '';
            }
        },
    },
    mounted() {
        //fix vue multiselect autocomplete="nope" bug
        if (this.searchable) this.$refs.multiselectRef.$refs.search.setAttribute('autocomplete', 'off');
        if (this.options.constructor.name === 'Object') {
            this.items = Object.values(this.options);
            if (this.value) this.selected = Object.values(this.options)?.find((o) => o === this.value) || '';
        } else {
            this.items = this.options;
            if (this.value) this.selected = this.options?.find((o) => o.value === this.value) || '';
        }
    },
};
